import React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/helpers/seo';

import Layout from '../components/layout';
import About from '../components/services/about';
import Entry from '../components/services/entry';
import Features from '../components/services/features';
import Servicess from '../components/services/services';
import useTranslation from '../hooks/useTranslation';

const Services = () => {
  const t = useTranslation();

  return (
    <Layout
      pages={{
        en: `/en/services`,
        fr: `/fr/services`,
      }}
    >
      <SEO title="Services" />
      <section className="Services">
        <Entry />
        <Features />
        <About />
        <Servicess />
        <div className="text-center py-1 mb-5">
          <Link
            to={t(`nav.links`)[3].path}
            className="btn btn-primary"
            style={{ fontSize: 20 }}
          >
            {t(`services.reservations`)}
          </Link>
        </div>
        {/*<Steps />*/}
        <div className="container Services__quote">
          <p>{t(`services.slogan`)}</p>
        </div>
      </section>
    </Layout>
  );
};

export default Services;
