import React from 'react';
import useTranslation from '../../hooks/useTranslation';

import HandShake from '../../svg/services/handshake.svg';
import DecisionMaking from '../../svg/services/decision-making.svg';
import Running from '../../svg/services/running.svg';
import Guarantee from '../../svg/services/guarantee.svg';

const features = [
  {
    title: `Fiabilité`,
    icon: <HandShake />,
  },
  {
    title: `Polyvalence`,
    icon: <DecisionMaking />,
  },
  {
    title: `Dynamisme`,
    icon: <Running />,
  },
  {
    title: `Qualité`,
    icon: <Guarantee />,
  },
];

const Features = () => {
  const t = useTranslation();

  const renderFeatures = () => (
    <ul className="Services__features__list">
      {t(`services.coreBusinessItems`).map((feature, index) => (
        <li key={index}>
          <article className="Services__features__article">
            <h3>{feature.title}</h3>
            <figure>{feature.icon}</figure>
          </article>
        </li>
      ))}
    </ul>
  );

  return (
    <section className="Services__features">
      <div className="container">
        <h2>{t(`services.coreBusinessTitle`)}</h2>
        {renderFeatures()}
      </div>
    </section>
  );
};

export default Features;
