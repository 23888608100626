import React from 'react';

import Couple from '../../svg/services/couple.svg';
import Cowork from '../../svg/services/cowork.svg';
import Salon from '../../svg/services/salon.svg';

const About = () => {
  return (
    <section className="Services__about">
      <div className="Services__about__container">
        <div>
          <div>
            <Couple />
          </div>
        </div>
        <div>
          <div>
            <Cowork />
          </div>
        </div>
        <div>
          <div>
            <Salon />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
