import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import useTranslation from '../../hooks/useTranslation';

const Entry = () => {
  const t = useTranslation();
  const { hall } = useStaticQuery(query);

  return (
    <section className="Services__entry">
      <div className="Services__entry__cover">
        <Img fluid={hall.childImageSharp.fluid} />
        <div className="Services__entry__heading">
          <h1>Services Plus</h1>
          <h2>{t(`services.title`)}</h2>
        </div>
      </div>
    </section>
  );
};

const query = graphql`
  {
    hall: file(name: { eq: "hall" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Entry;
